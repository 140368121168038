@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --black-gradient: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
    --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  }
  
  * {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Roboto';
}
  
  .feature-card:hover {
    background: var(--black-gradient);
    box-shadow: var(--card-shadow);
  }
  
  .feedback-card {
    background: transparent;
  }
  
  .feedback-card:hover {
    background: var(--black-gradient);
  }
  
  .bg-blue-gradient {
    background: linear-gradient(
      157.81deg,
      #def9fa -43.27%,
      #bef3f5 -21.24%,
      #9dedf0 12.19%,
      #7de7eb 29.82%,
      #5ce1e6 51.94%,
      #33bbcf 90.29%
    );
  }
  
  .slider-container {
    @apply w-full;
  }