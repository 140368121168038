*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: var(--fallback-b1, oklch(var(--b1) / 1));
  color: var(--fallback-bc, oklch(var(--bc) / 1));
}

@supports not (color: oklch(0% 0 0)) {
  :root {
    color-scheme: light;
    --fallback-p: #491eff;
    --fallback-pc: #d4dbff;
    --fallback-s: #ff41c7;
    --fallback-sc: #fff9fc;
    --fallback-a: #00cfbd;
    --fallback-ac: #00100d;
    --fallback-n: #2b3440;
    --fallback-nc: #d7dde4;
    --fallback-b1: #fff;
    --fallback-b2: #e5e6e6;
    --fallback-b3: #e5e6e6;
    --fallback-bc: #1f2937;
    --fallback-in: #00b3f0;
    --fallback-inc: #000;
    --fallback-su: #00ca92;
    --fallback-suc: #000;
    --fallback-wa: #ffc22d;
    --fallback-wac: #000;
    --fallback-er: #ff6f70;
    --fallback-erc: #000;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      color-scheme: dark;
      --fallback-p: #7582ff;
      --fallback-pc: #050617;
      --fallback-s: #ff71cf;
      --fallback-sc: #190211;
      --fallback-a: #00c7b5;
      --fallback-ac: #000e0c;
      --fallback-n: #2a323c;
      --fallback-nc: #a6adbb;
      --fallback-b1: #1d232a;
      --fallback-b2: #191e24;
      --fallback-b3: #15191e;
      --fallback-bc: #a6adbb;
      --fallback-in: #00b3f0;
      --fallback-inc: #000;
      --fallback-su: #00ca92;
      --fallback-suc: #000;
      --fallback-wa: #ffc22d;
      --fallback-wac: #000;
      --fallback-er: #ff6f70;
      --fallback-erc: #000;
    }
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 69.71% .329 342.55;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.alert {
  text-align: center;
  border-radius: var(--rounded-box, 1rem);
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  background-color: var(--alert-bg);
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  --alert-bg: var(--fallback-b2, oklch(var(--b2) / 1));
  --alert-bg-mix: var(--fallback-b1, oklch(var(--b1) / 1));
  grid-auto-flow: row;
  align-content: flex-start;
  place-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: grid;
}

@media (width >= 640px) {
  .alert {
    text-align: start;
    grid-template-columns: auto minmax(auto, 1fr);
    grid-auto-flow: column;
    justify-items: start;
  }
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge {
  border-radius: var(--rounded-badge, 1.9rem);
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

@media (hover: hover) {
  .label a:hover {
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  .menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
  }

  .table tr.hover:hover, .table tr.hover:nth-child(2n):hover {
    background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
    --tw-bg-opacity: 1;
  }

  .table-zebra tr.hover:hover, .table-zebra tr.hover:nth-child(2n):hover {
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
    --tw-bg-opacity: 1;
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--rounded-btn, .5rem);
  border-color: #0000;
  border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
  text-align: center;
  border-width: var(--border-btn, 1px);
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
  background-color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, opacity, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

.btn-circle {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

:where(.btninput[type="checkbox"]), :where(.btninput[type="radio"]) {
  appearance: none;
  width: auto;
}

.btninput[type="checkbox"]:after, .btninput[type="radio"]:after {
  content: var(--tw-content);
  --tw-content: attr(aria-label);
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.card-actions {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  opacity: .75;
  --tw-bg-opacity: 1;
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.card.image-full > .card-body {
  z-index: 20;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
  position: relative;
}

.carousel {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: inline-flex;
  overflow-x: scroll;
}

.carousel-item {
  box-sizing: content-box;
  scroll-snap-align: start;
  flex: none;
  display: flex;
}

.carousel-start .carousel-item {
  scroll-snap-align: start;
}

.carousel-center .carousel-item {
  scroll-snap-align: center;
}

.carousel-end .carousel-item {
  scroll-snap-align: end;
}

.chat {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: .75rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: grid;
}

.checkbox {
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --chkbg: var(--fallback-bc, oklch(var(--bc) / 1));
  --chkfg: var(--fallback-b1, oklch(var(--b1) / 1));
  --tw-border-opacity: .2;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

:root .countdown {
  line-height: 1em;
}

.countdown {
  display: inline-flex;
}

.countdown > * {
  height: 1em;
  display: inline-block;
  overflow-y: hidden;
}

.countdown > :before {
  content: "00\a 01\a 02\a 03\a 04\a 05\a 06\a 07\a 08\a 09\a 10\a 11\a 12\a 13\a 14\a 15\a 16\a 17\a 18\a 19\a 20\a 21\a 22\a 23\a 24\a 25\a 26\a 27\a 28\a 29\a 30\a 31\a 32\a 33\a 34\a 35\a 36\a 37\a 38\a 39\a 40\a 41\a 42\a 43\a 44\a 45\a 46\a 47\a 48\a 49\a 50\a 51\a 52\a 53\a 54\a 55\a 56\a 57\a 58\a 59\a 60\a 61\a 62\a 63\a 64\a 65\a 66\a 67\a 68\a 69\a 70\a 71\a 72\a 73\a 74\a 75\a 76\a 77\a 78\a 79\a 80\a 81\a 82\a 83\a 84\a 85\a 86\a 87\a 88\a 89\a 90\a 91\a 92\a 93\a 94\a 95\a 96\a 97\a 98\a 99\a ";
  white-space: pre;
  top: calc(var(--value) * -1em);
  text-align: center;
  transition: all 1s cubic-bezier(1, 0, 0, 1);
  position: relative;
}

.divider {
  white-space: nowrap;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider:before, .divider:after {
  content: var(--tw-content);
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  --tw-content: "";
  flex-grow: 1;
  width: 100%;
  height: .125rem;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > :not(summary):focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.dropdown .dropdown-content {
  position: absolute;
}

.dropdown:not(details) .dropdown-content {
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.dropdown-end .dropdown-content {
  inset-inline-end: 0;
}

.dropdown-left .dropdown-content {
  bottom: auto;
  transform-origin: 100%;
  inset-inline-end: 100%;
  top: 0;
}

.dropdown-right .dropdown-content {
  bottom: auto;
  transform-origin: 0;
  inset-inline-start: 100%;
  top: 0;
}

.dropdown-bottom .dropdown-content {
  transform-origin: top;
  top: 100%;
  bottom: auto;
}

.dropdown-top .dropdown-content {
  transform-origin: bottom;
  top: auto;
  bottom: 100%;
}

.dropdown-end.dropdown-right .dropdown-content, .dropdown-end.dropdown-left .dropdown-content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:not(.dropdown-hover):focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  visibility: visible;
  opacity: 1;
}

@media (hover: hover) {
  .dropdown.dropdown-hover:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }

  .btn:hover {
    border-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-border-opacity)));
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:hover {
      background-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%, black);
      border-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%, black);
    }
  }

  @supports not (color: oklch(0% 0 0)) {
    .btn:hover {
      background-color: var(--btn-color, var(--fallback-b2));
      border-color: var(--btn-color, var(--fallback-b2));
    }
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-ghost:hover {
    border-color: #0000;
  }

  @supports (color: oklch(0% 0 0)) {
    .btn-ghost:hover {
      background-color: var(--fallback-bc, oklch(var(--bc) / .2));
    }
  }

  .btn-outline.btn-success:hover {
    color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-success:hover {
      background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-warning:hover {
    color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-warning:hover {
      background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-error:hover {
    color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-error:hover {
      background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    }
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-border-opacity: 0;
    --tw-bg-opacity: .2;
    --tw-text-opacity: .2;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btninput[type="checkbox"]:checked:hover, .btninput[type="radio"]:checked:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .dropdown.dropdown-hover:hover .dropdown-content {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
    cursor: pointer;
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  @supports (color: oklch(0% 0 0)) {
    :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
      background-color: var(--fallback-bc, oklch(var(--bc) / .1));
    }
  }
}

.dropdown:is(details) summary::-webkit-details-marker {
  display: none;
}

.footer {
  grid-auto-flow: row;
  place-items: start;
  gap: 2.5rem 1rem;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  display: grid;
}

.footer > * {
  place-items: start;
  gap: .5rem;
  display: grid;
}

@media (width >= 48rem) {
  .footer {
    grid-auto-flow: column;
  }

  .footer-center {
    grid-auto-flow: row dense;
  }
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.hero {
  background-position: center;
  background-size: cover;
  place-items: center;
  width: 100%;
  display: grid;
}

.hero > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.hero-overlay {
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .5;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: 100%;
}

.hero-content {
  z-index: 0;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 80rem;
  padding: 1rem;
  display: flex;
}

.input {
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.input-md[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.join {
  border-radius: var(--rounded-btn, .5rem);
  align-items: stretch;
  display: inline-flex;
}

.join :where(.join-item), .join .join-item:not(:first-child):not(:last-child), .join :not(:first-child):not(:last-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .join-item {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.join .dropdown .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .dropdown .join-item {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

.join :where(.join-item:first-child:not(:last-child)), .join :where(:first-child:not(:last-child) .join-item) {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}

.join .join-item:last-child:not(:first-child), .join :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.join :where(.join-item:last-child:not(:first-child)), .join :where(:last-child:not(:first-child) .join-item) {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

@supports not selector(:has(*)) {
  :where(.join *) {
    border-radius: inherit;
  }
}

@supports selector(:has(*)) {
  :where(.join :has(.join-item)) {
    border-radius: inherit;
  }
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu {
  flex-flow: column wrap;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.menu :where(li ul) {
  white-space: nowrap;
  margin-inline-start: 1rem;
  padding-inline-start: .5rem;
  position: relative;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  -webkit-user-select: none;
  user-select: none;
  grid-auto-columns: minmax(auto, max-content) auto max-content;
  grid-auto-flow: column;
  align-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: grid;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: var(--fallback-bc, oklch(var(--bc) / .3));
}

.menu :where(li > .menu-dropdown:not(.menu-dropdown-show)) {
  display: none;
}

:where(.menu li) {
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  position: relative;
}

:where(.menu li) .badge {
  justify-self: end;
}

.modal {
  pointer-events: none;
  opacity: 0;
  overscroll-behavior: contain;
  z-index: 999;
  color: inherit;
  background-color: #0000;
  justify-items: center;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  margin: 0;
  padding: 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: grid;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  overscroll-behavior: contain;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  --tw-bg-opacity: 1;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 91.6667%;
  max-width: 32rem;
  max-height: calc(100vh - 5em);
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open] {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.modal-action {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

.modal-toggle {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

:root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open])) {
  scrollbar-gutter: stable;
  overflow: hidden;
}

.navbar {
  padding: var(--navbar-padding, .5rem);
  align-items: center;
  width: 100%;
  min-height: 4rem;
  display: flex;
}

:where(.navbar > :not(script, style)) {
  align-items: center;
  display: inline-flex;
}

.navbar-start {
  justify-content: flex-start;
  width: 50%;
}

.navbar-center {
  flex-shrink: 0;
}

.navbar-end {
  justify-content: flex-end;
  width: 50%;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16.1px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  border-width: 1px;
  border-color: #0000;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.steps .step {
  text-align: center;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  min-width: 4rem;
  display: grid;
}

.badge-accent {
  border-color: var(--fallback-a, oklch(var(--a) / var(--tw-border-opacity)));
  background-color: var(--fallback-a, oklch(var(--a) / var(--tw-bg-opacity)));
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

.badge-outline {
  color: currentColor;
  --tw-border-opacity: .5;
  background-color: #0000;
  border-color: currentColor;
}

.badge-outline.badge-neutral {
  color: var(--fallback-n, oklch(var(--n) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.badge-outline.badge-primary {
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.badge-outline.badge-secondary {
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.badge-outline.badge-accent {
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.badge-outline.badge-info {
  color: var(--fallback-in, oklch(var(--in) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.badge-outline.badge-success {
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.badge-outline.badge-warning {
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.badge-outline.badge-error {
  color: var(--fallback-er, oklch(var(--er) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .btn {
    animation: button-pop var(--animation-btn, .25s) ease-out;
  }
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

@supports not (color: oklch(0% 0 0)) {
  .btn {
    background-color: var(--btn-color, var(--fallback-b2));
    border-color: var(--btn-color, var(--fallback-b2));
  }

  .btn-success {
    --btn-color: var(--fallback-su);
  }

  .btn-warning {
    --btn-color: var(--fallback-wa);
  }

  .btn-error {
    --btn-color: var(--fallback-er);
  }
}

@supports (color: color-mix(in oklab, black, black)) {
  .btn-outline.btn-success.btn-active {
    background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
  }

  .btn-outline.btn-warning.btn-active {
    background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
  }

  .btn-outline.btn-error.btn-active {
    background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
  }
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

@supports (color: oklch(0% 0 0)) {
  .btn-success {
    --btn-color: var(--su);
  }

  .btn-warning {
    --btn-color: var(--wa);
  }

  .btn-error {
    --btn-color: var(--er);
  }
}

.btn-success {
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-su, oklch(var(--su) / 1));
  --tw-text-opacity: 1;
}

.btn-warning {
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  outline-color: var(--fallback-wa, oklch(var(--wa) / 1));
  --tw-text-opacity: 1;
}

.btn-error {
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-er, oklch(var(--er) / 1));
  --tw-text-opacity: 1;
}

.btn.glass {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-ghost {
  color: currentColor;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
  outline-color: currentColor;
}

.btn-ghost.btn-active {
  background-color: var(--fallback-bc, oklch(var(--bc) / .2));
  border-color: #0000;
}

.btn-outline.btn-success {
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-success.btn-active {
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-warning {
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-warning.btn-active {
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-error {
  color: var(--fallback-er, oklch(var(--er) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-error.btn-active {
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-border-opacity: 0;
  --tw-bg-opacity: .2;
  --tw-text-opacity: .2;
}

.btninput[type="checkbox"]:checked, .btninput[type="radio"]:checked {
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

.btninput[type="checkbox"]:checked:focus-visible, .btninput[type="radio"]:checked:focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: unset;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered {
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-border-opacity: 1;
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.card.image-full :where(figure) {
  border-radius: inherit;
  overflow: hidden;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.checkbox:focus {
  box-shadow: none;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.checkbox:disabled {
  cursor: not-allowed;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .2;
  --tw-bg-opacity: 1;
  border-width: 0;
  border-color: #0000;
}

.checkbox:checked, .checkbox[aria-checked="true"] {
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-color: var(--chkbg);
  background-image: linear-gradient(-45deg, transparent 65%, var(--chkbg) 65.99%), linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%), linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%), linear-gradient(45deg, var(--chkbg) 30%, var(--chkfg) 30.99%, var(--chkfg) 40%, transparent 40.99%), linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
}

.checkbox:indeterminate {
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(-90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(0deg, var(--chkbg) 43%, var(--chkfg) 43%, var(--chkfg) 57%, var(--chkbg) 57%);
  --tw-bg-opacity: 1;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.divider:not(:empty) {
  gap: 1rem;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.input input {
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
  background-color: #0000;
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input:has( > input[disabled]), .input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}

.input:has( > input[disabled])::placeholder, .input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.input:has( > input[disabled]) > input[disabled] {
  cursor: not-allowed;
}

.input::-webkit-date-and-time-value {
  text-align: inherit;
}

.join > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.\!loading {
  pointer-events: none !important;
  aspect-ratio: 1 !important;
  background-color: currentColor !important;
  width: 1.5rem !important;
  display: inline-block !important;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E") !important;
  mask-position: center !important;
  mask-size: 100% !important;
  mask-repeat: no-repeat !important;
}

.loading {
  pointer-events: none;
  aspect-ratio: 1;
  background-color: currentColor;
  width: 1.5rem;
  display: inline-block;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-position: center;
  mask-size: 100%;
  mask-repeat: no-repeat;
}

.loading-dots {
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_qM83%7Banimation:spinner_8HQG 1.05s infinite%7D.spinner_oXPr%7Banimation-delay:.1s%7D.spinner_ZTLf%7Banimation-delay:.2s%7D@keyframes spinner_8HQG%7B0%25,57.14%25%7Banimation-timing-function:cubic-bezier(0.33,.66,.66,1);transform:translate(0)%7D28.57%25%7Banimation-timing-function:cubic-bezier(0.33,0,.66,.33);transform:translateY(-6px)%7D100%25%7Btransform:translate(0)%7D%7D%3C/style%3E%3Ccircle class='spinner_qM83' cx='4' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_oXPr' cx='12' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_ZTLf' cx='20' cy='12' r='3'/%3E%3C/svg%3E");
}

.loading-lg {
  width: 2.5rem;
}

:where(.menu li:empty) {
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  --tw-bg-opacity: 1;
  height: 1px;
  margin: .5rem 1rem;
}

.menu :where(li ul):before {
  bottom: .75rem;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  content: "";
  inset-inline-start: 0;
  --tw-bg-opacity: 1;
  width: 1px;
  position: absolute;
  top: .75rem;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  text-align: start;
  text-wrap: balance;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):is(summary):not(.active, .btn):focus-visible, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):is(summary):not(.active, .btn):focus-visible {
  cursor: pointer;
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  outline-offset: 2px;
  --tw-text-opacity: 1;
  outline: 2px solid #0000;
}

.menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

.menu :where(li > details > summary)::-webkit-details-marker {
  display: none;
}

.menu :where(li > details > summary):after, .menu :where(li > .menu-dropdown-toggle):after {
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  justify-self: end;
  width: .5rem;
  height: .5rem;
  margin-top: -.5rem;
  transition-property: transform, margin-top;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.menu :where(li > details[open] > summary):after, .menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after {
  margin-top: 0;
  transform: rotate(225deg);
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  direction: ltr;
  --tw-bg-opacity: 1;
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  --tw-translate-y: -50%;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  background-color: #0006;
  animation: .2s ease-out modal-pop;
}

.modal-backdrop {
  z-index: -1;
  color: #0000;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: stretch stretch;
  display: grid;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box, .modal[open] .modal-box {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.modal-action > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-x-reverse: 0;
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.select-disabled, .select:disabled, .select[disabled] {
  cursor: not-allowed;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}

.select-disabled::placeholder, .select:disabled::placeholder, .select[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

[dir="rtl"] .select {
  background-position: 12px calc(1px + 50%), 16px calc(1px + 50%);
}

.skeleton {
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  will-change: background-position;
  background-image: linear-gradient(105deg, transparent 0%, transparent 40%, var(--fallback-b1, oklch(var(--b1) / 1)) 50%, transparent 60%, transparent 100%);
  --tw-bg-opacity: 1;
  background-position-x: -50%;
  background-repeat: no-repeat;
  background-size: 200%;
  animation: 1.8s ease-in-out infinite skeleton;
}

@media (prefers-reduced-motion) {
  .skeleton {
    animation-duration: 15s;
  }
}

@keyframes skeleton {
  from {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}

.steps .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  content: "";
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: .5rem;
  margin-inline-start: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  width: 2rem;
  height: 2rem;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.artboard.phone {
  width: 320px;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.card-side {
  flex-direction: row;
  align-items: stretch;
}

.card-side :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: unset;
  border-end-end-radius: unset;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card-side :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card-side figure > * {
  max-width: unset;
}

:where(.card-side figure > *) {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.join.join-vertical {
  flex-direction: column;
}

.join.join-vertical .join-item:first-child:not(:last-child), .join.join-vertical :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join.join-vertical .join-item:last-child:not(:first-child), .join.join-vertical :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
}

.join.join-horizontal {
  flex-direction: row;
}

.join.join-horizontal .join-item:first-child:not(:last-child), .join.join-horizontal :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: inherit;
}

.join.join-horizontal .join-item:last-child:not(:first-child), .join.join-horizontal :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: 0;
}

.menu-horizontal {
  flex-direction: row;
  display: inline-flex;
}

.menu-horizontal > li:not(.menu-title) > details > ul {
  position: absolute;
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.avatar.online:before {
  content: "";
  z-index: 10;
  background-color: var(--fallback-su, oklch(var(--su) / var(--tw-bg-opacity)));
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-b1, oklch(var(--b1) / 1));
  --tw-bg-opacity: 1;
  border-radius: 9999px;
  width: 15%;
  height: 15%;
  display: block;
  position: absolute;
  top: 7%;
  right: 7%;
}

.avatar.offline:before {
  content: "";
  z-index: 10;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-b1, oklch(var(--b1) / 1));
  --tw-bg-opacity: 1;
  border-radius: 9999px;
  width: 15%;
  height: 15%;
  display: block;
  position: absolute;
  top: 7%;
  right: 7%;
}

.card-compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-compact .card-title {
  margin-bottom: .25rem;
}

.card-normal .card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.card-normal .card-title {
  margin-bottom: .75rem;
}

.join.join-vertical > :where(:not(:first-child)) {
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
}

.join.join-horizontal > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.menu-horizontal > li:not(.menu-title) > details > ul {
  margin-inline-start: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-top: 1rem;
  padding-inline-end: .5rem;
}

.menu-horizontal > li > details > ul:before {
  content: none;
}

:where(.menu-horizontal > li:not(.menu-title) > details > ul) {
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
}

.menu-sm :where(li:not(.menu-title) > :not(ul, details, .menu-title)), .menu-sm :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  padding: .25rem .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu-sm .menu-title {
  padding: .5rem .75rem;
}

.modal-top :where(.modal-box) {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  --tw-translate-y: -2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  max-width: none;
}

.modal-middle :where(.modal-box) {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  width: 91.6667%;
  max-width: 32rem;
}

.modal-bottom :where(.modal-box) {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  max-width: none;
}

.steps-horizontal .step {
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  min-width: 4rem;
}

.steps-horizontal .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  width: 100%;
  height: .5rem;
  margin-inline-start: -100%;
}

:is([dir="rtl"] .steps-horizontal .step):before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 0px;
}

.steps-vertical .step {
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
  min-height: 4rem;
}

.steps-vertical .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  width: .5rem;
  height: 100%;
  margin-inline-start: 50%;
}

:is([dir="rtl"] .steps-vertical .step):before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 50%;
}

.pointer-events-none {
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.left-5 {
  left: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.top-1\/2 {
  top: 50%;
}

.z-20 {
  z-index: 20;
}

.z-\[1\] {
  z-index: 1;
}

.m-1 {
  margin: .25rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[430\] {
  margin-bottom: 430px;
}

.me-3 {
  margin-inline-end: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-1\/2 {
  height: 50%;
}

.h-12 {
  height: 3rem;
}

.h-24 {
  height: 6rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[200\] {
  height: 200px;
}

.h-\[27\.6px\] {
  height: 27.6px;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-\[70vh\] {
  height: 70vh;
}

.h-\[80vh\] {
  height: 80vh;
}

.h-dvh {
  height: 100dvh;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-svh {
  min-height: 100svh;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[28rem\] {
  width: 28rem;
}

.w-\[42\.6px\] {
  width: 42.6px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-screen {
  width: 100vw;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-\[370px\] {
  max-width: 370px;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.-translate-y-1\/2 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -50%;
}

.translate-y-10 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 2.5rem;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: 25s linear infinite marquee;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-x-reverse: 0;
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-box {
  border-radius: var(--rounded-box, 1rem);
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-gray-200 {
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.border-gray-300 {
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.border-white\/5 {
  border-color: #ffffff0d;
}

.bg-base-100 {
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
}

.bg-gray-50 {
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-main-color {
  background-color: rgb(183 110 121 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-neutral {
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
}

.bg-slate-50 {
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-white\/5 {
  background-color: #ffffff0d;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-main-color {
  --tw-gradient-from: #b76e79 var(--tw-gradient-from-position);
  --tw-gradient-to: #b76e7900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red-300 {
  --tw-gradient-from: #fca5a5 var(--tw-gradient-from-position);
  --tw-gradient-to: #fca5a500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-red-100 {
  --tw-gradient-to: #fee2e2 var(--tw-gradient-to-position);
}

.to-red-300 {
  --tw-gradient-to: #fca5a5 var(--tw-gradient-to-position);
}

.to-to-color {
  --tw-gradient-to: #ffdab9 var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.stroke-info {
  stroke: var(--fallback-in, oklch(var(--in) / 1));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[2\%\] {
  padding: 2%;
}

.p-\[4\%\] {
  padding: 4%;
}

.p-\[5\%\] {
  padding: 5%;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-\[1\%\] {
  padding-bottom: 1%;
}

.pl-0 {
  padding-left: 0;
}

.pl-\[2\%\] {
  padding-left: 2%;
}

.pr-\[2\%\] {
  padding-right: 2%;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-\[1\%\] {
  padding-top: 1%;
}

.text-center {
  text-align: center;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-\[32\.4px\] {
  line-height: 32.4px;
}

.leading-\[32px\] {
  line-height: 32px;
}

.leading-relaxed {
  line-height: 1.625;
}

.text-black {
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-black\/75 {
  color: #000000bf;
}

.text-blue-500 {
  color: rgb(59 130 246 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-gray-500 {
  color: rgb(107 114 128 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-gray-900 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-green-700 {
  color: rgb(21 128 61 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-main-color {
  color: rgb(183 110 121 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-neutral-content {
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.text-white {
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.line-through {
  text-decoration-line: line-through;
}

.opacity-0 {
  opacity: 0;
}

.shadow {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.shadow-2xl {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
}

.shadow-lg {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
}

.shadow-md {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
}

.shadow-xl {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[--duration\:30s\] {
  --duration: 30s;
}

:root {
  --black-gradient: linear-gradient(144.39deg, #fff -278.56%, #6d6d6d -78.47%, #11101d 91.61%);
  --card-shadow: 0px 20px 100px -10px #42475b1a;
}

* {
  scroll-behavior: smooth;
}

body {
  font-family: Roboto;
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-card {
  background: none;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.bg-blue-gradient {
  background: linear-gradient(157.81deg, #def9fa -43.27%, #bef3f5 -21.24%, #9dedf0 12.19%, #7de7eb 29.82%, #5ce1e6 51.94%, #33bbcf 90.29%);
}

.slider-container {
  width: 100%;
}

@media (hover: hover) {
  .hover\:btn-outline:hover:hover {
    border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
    background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
    color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-primary:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .hover\:btn-outline:hover.btn-primary:hover {
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-primary:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }

    .hover\:btn-outline:hover.btn-secondary:hover {
      background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    }
  }

  .hover\:btn-outline:hover.btn-secondary:hover {
    color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-secondary:hover {
      background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    }

    .hover\:btn-outline:hover.btn-accent:hover {
      background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    }
  }

  .hover\:btn-outline:hover.btn-accent:hover {
    color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-accent:hover {
      background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    }

    .hover\:btn-outline:hover.btn-success:hover {
      background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    }
  }

  .hover\:btn-outline:hover.btn-success:hover {
    color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-success:hover {
      background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    }

    .hover\:btn-outline:hover.btn-info:hover {
      background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    }
  }

  .hover\:btn-outline:hover.btn-info:hover {
    color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-info:hover {
      background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    }

    .hover\:btn-outline:hover.btn-warning:hover {
      background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    }
  }

  .hover\:btn-outline:hover.btn-warning:hover {
    color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-warning:hover {
      background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    }

    .hover\:btn-outline:hover.btn-error:hover {
      background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    }
  }

  .hover\:btn-outline:hover.btn-error:hover {
    color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .hover\:btn-outline:hover.btn-error:hover {
      background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    }
  }
}

@supports (color: color-mix(in oklab, black, black)) {
  .hover\:btn-outline:hover.btn-primary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
  }

  .hover\:btn-outline:hover.btn-secondary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
  }

  .hover\:btn-outline:hover.btn-accent.btn-active {
    background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
  }

  .hover\:btn-outline:hover.btn-success.btn-active {
    background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
  }

  .hover\:btn-outline:hover.btn-info.btn-active {
    background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
  }

  .hover\:btn-outline:hover.btn-warning.btn-active {
    background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
  }

  .hover\:btn-outline:hover.btn-error.btn-active {
    background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
  }
}

.hover\:btn-outline:hover {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  background-color: #0000;
  border-color: currentColor;
}

.hover\:btn-outline:hover.btn-active {
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-primary {
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-primary.btn-active {
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-secondary {
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-secondary.btn-active {
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-accent {
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-accent.btn-active {
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-success {
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-success.btn-active {
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-info {
  color: var(--fallback-in, oklch(var(--in) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-info.btn-active {
  color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-warning {
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-warning.btn-active {
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-error {
  color: var(--fallback-er, oklch(var(--er) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.hover\:btn-outline:hover.btn-error.btn-active {
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

@media (width >= 1024px) {
  .lg\:card-title {
    align-items: center;
    gap: .5rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    display: flex;
  }

  .lg\:card-side {
    flex-direction: row;
    align-items: stretch;
  }

  .lg\:card-side :where(figure:first-child) {
    border-start-start-radius: inherit;
    border-start-end-radius: unset;
    border-end-end-radius: unset;
    border-end-start-radius: inherit;
    overflow: hidden;
  }

  .lg\:card-side :where(figure:last-child) {
    border-start-start-radius: unset;
    border-start-end-radius: inherit;
    border-end-end-radius: inherit;
    border-end-start-radius: unset;
    overflow: hidden;
  }

  .lg\:card-side figure > * {
    max-width: unset;
  }

  :where(.lg\:card-side figure > *) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .card-compact .lg\:card-title {
    margin-bottom: .25rem;
  }

  .card-normal .lg\:card-title {
    margin-bottom: .75rem;
  }
}

.hover\:scale-105:hover {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.hover\:text-blue-700:hover {
  color: rgb(29 78 216 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-lg:hover {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
}

.hover\:\[animation-play-state\:paused\]:hover {
  animation-play-state: paused;
}

.focus\:border-blue-500:focus {
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-4:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

@media (width >= 640px) {
  .sm\:m-6 {
    margin: 1.5rem;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-\[5vh\] {
    height: 5vh;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:text-center {
    text-align: center;
  }
}

@media (width >= 768px) {
  .md\:m-6 {
    margin: 1.5rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-\[5vh\] {
    height: 5vh;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-\[75\%\] {
    width: 75%;
  }

  .md\:w-\[80\%\] {
    width: 80%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:m-6 {
    margin: 1.5rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-dvh {
    height: 100dvh;
  }

  .lg\:w-\[50\%\] {
    width: 50%;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-600 {
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
    --tw-border-opacity: 1;
  }

  .dark\:border-gray-700 {
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
    --tw-border-opacity: 1;
  }

  .dark\:bg-gray-900 {
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
    --tw-bg-opacity: 1;
  }

  .dark\:text-gray-300 {
    color: rgb(209 213 219 / var(--tw-text-opacity));
    --tw-text-opacity: 1;
  }

  .dark\:text-gray-400 {
    color: rgb(156 163 175 / var(--tw-text-opacity));
    --tw-text-opacity: 1;
  }

  .dark\:text-white {
    color: rgb(255 255 255 / var(--tw-text-opacity));
    --tw-text-opacity: 1;
  }

  .dark\:placeholder-gray-400::placeholder {
    color: rgb(156 163 175 / var(--tw-placeholder-opacity));
    --tw-placeholder-opacity: 1;
  }

  .dark\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .dark\:focus\:border-blue-500:focus {
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
    --tw-border-opacity: 1;
  }

  .dark\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  }

  .dark\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
  }
}
/*# sourceMappingURL=index.b5881072.css.map */
